@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

.footer {
    margin-top: 40px;
    width: 100%;
    background-color: #171717;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 1rem;
    z-index: 50;
    height: 130px;
    /* position: fixed; */
    left: 0;
    bottom: 0;
}
.footerItem p {
    color: #f4f4f4;
    font-size: 1.5rem;
    font-family: Raleway;
    font-weight: 700;
    cursor: pointer;
}
.footerItem:hover {
    transform: scale(1.05);
}
.footerItem:active {
    color: gray;
}
a {
    text-decoration: none;
}


@media (max-width:960px) {
    .footer {
        height: 110px;
        margin-top: 20px;
        
    }
    .footerItem p {
        font-size: 1.3rem;
    }
}
@media (max-width: 500px){
    .footerItem p {
        font-size: 1rem;
    }
}