@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

.work {
    display: flex;
    max-width: 1300px;
    margin: 1rem auto;
    align-items: center;
    flex-direction: column;
}
.about {
    text-align: center;
    height: 200px;
    width: 800px;
    margin-left: 25%;
    margin-right: 25%;
}

.about > h1 {
    color: #141414;
    margin: 20px;
    font-family: Raleway;
    font-size: 2rem;
}
.about > p {
    font-size: 1.2rem;
    color: gray;
    font-family: Raleway;
    margin: 20px;
}
.workContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 100px;
}

.job {
    min-height: 480px;
    min-width: 400px;
    max-width: 400px;
    margin: 10px;
    margin-bottom: 40px;
    text-align: center;

}
.job h1 {
    font-family: Raleway;
    color: #141414;
    font-size: 1.6rem;
}
.content {
    padding: 10px;
    color: #333;
    font-family: Raleway;
    font-size: 1.1rem;
}
.label {
    font-family: Raleway;
    color: green;
    font-weight: 800;
    padding: 10px;
}


.company {
    height: 80px;
    width: auto;
}
.company4 {
    height: 80px;
    width: auto;
    background-color: #444;
    border-radius: 10px;
}
.skillLabel {
    font-family: Raleway;
    color: #141414;
    font-weight: bold;
    padding: 8px;
}
.skills {
    width: 355px;
    height: 60px;    
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 5px;
}
.skill {
    height: 25px;
    width: 87px;
    border-radius: 25px;
    border: none;
    font-family: Raleway;
    font-weight: 600;
    background-color: #911F27;
    color: #f4f4f4;
    padding: 3px;
    font-size: .9rem;
}

@media (max-width:1350px) {
    .workContainer {
        flex-wrap: wrap;
    }
}

@media (max-width:960px) {
    .about {
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .about > h1 {
        font-size: 1.5rem;
    }

    .about > p {
        font-size: 1rem;
        margin: 10px;
    }
    .workContainer {
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 25px;
    }

}