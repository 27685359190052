@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

.homescreen {
    display: flex;
    max-width: 1300px;
    margin: 2rem auto;
    align-items: center;
    flex-direction: column;

}
/* .about {
    text-align: center;
    height: 300px;
    width: 800px;
    box-shadow: 0 1px 4px rgba(0,0,0,.4);
} */
.about {
    text-align: center;
    height: 200px;
    width: 800px;
    margin-left: 25%;
    margin-right: 25%;
}

.about > h1 {
    color: #141414;
    margin: 20px;
    font-family: Raleway;
    font-size: 2rem;
}
.about > p {
    font-size: 1.2rem;
    color: gray;
    font-family: Raleway;
    margin: 20px;
}

.container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 20px;
}
.item {
    width: 60%;
    height: 200px;
    margin: 25px;
    border: 5px solid #141414;
    background-color: #F7F6F2;
    border-radius: 9px;
    cursor: pointer;
    text-decoration: none;
}
.item p {
    margin: 20px;
    color: gray;
}
.item h1 {
    margin-top: 55px;
    color: #082032;
}
.item:hover {
    transform: scale(1.05);
}

.title {
    padding-top: 300px;
    display: flex;
    max-width: 1300px;
    margin: 2rem auto;
    align-items: center;
    flex-direction: column;
}
.info {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 300px;
}




@media (max-width:960px) {
    .about {
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .about > h1 {
        font-size: 1.5rem;
    }

    .about > p {
        font-size: 1rem;
        margin: 10px;
    }

    .item h1 {
        font-size: 1.5rem;
        margin-top: 30px;
    }
    .item {
        height: 150px;
    }
}